<template>
  <!--  登录样式1  -->
     <div class="wrapper" >
         <div class="login1">
             <el-tabs type="card" v-model="activeName" >
                 <el-tab-pane label="Account&Password" name="first">
                     <div class="login2">
                         <el-form :model="user" :rules="rules" ref="userForm">
                             <el-form-item prop="username">
                                 <el-input size="medium" class="m5_0" prefix-icon="el-icon-user" v-model="user.username"></el-input>
                             </el-form-item>
                             <el-form-item prop="password">
                                 <el-input size="medium" class="m5_0" prefix-icon="el-icon-lock" show-password v-model="user.password"></el-input>
                             </el-form-item>
                             <el-form-item style="margin: 10px 0; text-align: right">
                                 <el-button type="warning" size="small"  autocomplete="off" @click="$router.push('/register')">REGISTER</el-button>
                                 <el-button type="primary" size="small"  autocomplete="off" @click="login">LOG IN</el-button>
                             </el-form-item>
                             <el-form-item>
                                 <el-button type="text" size="small" class="ml200" autocomplete="off" @click="handlePass">Forgot password</el-button>
                             </el-form-item>
                         </el-form>
                     </div>
                 </el-tab-pane>
                 <el-tab-pane label="Mail" name="second">
                     <div class="login_mail">
                         <el-form :model="user" :rules="rules" ref="userForm">
                             <el-form-item prop="email">
                                 <el-input size="medium" class="m5_0" prefix-icon="el-icon-message" v-model="user.email"></el-input>
                             </el-form-item>
                             <el-form-item prop="code">
                                 <el-input size="small" class="mailcode" prefix-icon="el-icon-lock" v-model="user.code"></el-input>
                                 <el-button type="primary" size="small" class="ml-5" @click="sendEmailCode(1)">Get Code</el-button>
                             </el-form-item>
                             <el-form-item class="login_button" >
                                 <el-button type="primary" size="small"  autocomplete="off" @click="loginEmail">LOG IN</el-button>
                             </el-form-item>
                             <el-form-item>
                                 <el-button type="text" size="small" class="ml200" autocomplete="off" @click="handlePass">Forgot password</el-button>
                             </el-form-item>
                         </el-form>
                     </div>
                 </el-tab-pane>
             </el-tabs>
         </div>

         <el-dialog title="Retrieve your password" :visible.sync="dialogFormVisible" width="30%" class="br20" append-to-body>
             <el-form label-width="100px" size="small">
                 <el-form-item label="Email">
                     <el-input size="medium" class="width295" v-model="pass.email" auto-complete="off"></el-input>
                 </el-form-item>
                 <el-form-item label="Captcha">
                     <el-input size="medium" class="width180" v-model="pass.code"></el-input>
                     <el-button type="primary" size="medium" class="ml-5" @click="sendEmailCode(2)">Get code</el-button>
                 </el-form-item>
             </el-form>
             <div slot="footer" class="dialog-footer">
                 <el-button @click="dialogFormVisible = false">Cancel</el-button>
                 <el-button type="primary" @click="passwordBack">Reset password</el-button>
             </div>
         </el-dialog>
     </div>
</template>
<script>
import router, {setRoutes} from "@/router";
import identify from '../components/identify.vue'

export default {
  name: "Login",
  components: { identify },
  data() {
    return {
      activeName: 'first',
      user: {},
      code:'',
      //图片验证码
      identifyCode: '',
      //验证码规则
      identifyCodes: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      rules: {
        username: [
          { required: true, message: 'Please enter a username', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please confirm your password', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
      },
      dialogFormVisible: false,
      pass: {},
    }
  },
  methods: {
    router() {
      return router
    },
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          this.request.post("/user/login", this.user).then(res => {
            if(res.code === '200') {
              localStorage.setItem("user",JSON.stringify(res.data)) //存储用户信息到浏览器
              localStorage.setItem("menus",JSON.stringify(res.data.menus))
                console.log(res.data)
              //动态设置当前用户路由
              setRoutes()
              this.$message.success("Login successful!")

              if (res.data.role === 'ROLE_USER') {
                this.$router.push("/front/home")
              }else {
                this.$router.push("/home")
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    loginEmail() {
      if (!this.user.email){
          this.$message.warning("Please enter your email address")
          return
      }
      if (!this.user.code){
          this.$message.warning("Please enter a verification code")
          return;
      }
      this.request.post("/user/loginEmail", this.user).then(res => {
        if(res.code === '200') {
            localStorage.setItem("user",JSON.stringify(res.data)) //存储用户信息到浏览器
            localStorage.setItem("menus",JSON.stringify(res.data.menus))
            //动态设置当前用户路由
            setRoutes()
            this.$router.push("/front/home")
            this.$message.success("Login successful!")
        } else {
            this.$message.error(res.msg)
        }
      })
    },
    sendEmailCode(type){
      let email;
      if (type === 1){
          email = this.user.email
      }else if (type === 2){
          email = this.pass.email
      }
      if (!email){
          this.$message.warning("Please enter your email address")
          return
      }
      if (!/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(email)){
          this.$message.warning("Please enter the correct email address")
          return
      }
      this.request.get("/user/email/"  + email + "/" + type).then(res => {
          if (res.code === '200'){
              this.$message.success("The sending was successful")
          }else {
              this.$message.error(res.msg)
          }
      })
    },
    refreshCode () {
        this.identifyCode = ''
        this.makeCode(this.identifyCodes, 4)
    },
    makeCode (data, len) {
        for (let i = 0; i < len; i++) {
            this.identifyCode += data[this.randomNum(0, data.length - 1)]
        }
    },
    randomNum (min, max) {
        max = max + 1
        return Math.floor(Math.random() * (max - min) + min)
    },
    handlePass(){
      this.dialogFormVisible = true;
      this.pass = {}
    },
    passwordBack(){
      this.request.put("/user/reset", this.pass).then(res => {
          if (res.code === '200'){
              this.$message({
                duration: 10000,
                message: 'The password is reset successfully, and the password is 123',
                type: 'success'
              });

              this.dialogFormVisible = false
          }else {
              this.$message.error(res.msg)
          }
      })
    },
  },
}
</script>

<style >
.wrapper {
  background: #000;
  width:100%;
  height:100%;
  position:fixed;
  background-size:100% 120%;
}
.login1 {
  margin: 175px auto; 
  background: white; 
  width: 400px;
  height: 300px; 
  border-radius: 10px; 
  overflow: hidden
}
.login2 {
  margin: 10px auto; 
  background-color: #fff; 
  width: 300px; 
  height: 350px; 
  padding: 20px; 
  border-radius: 10px
}
.login_mail {
  margin: 10px auto; 
  background-color: #fff; 
  width: 300px; 
  height: 300px; 
  padding: 20px; 
  border-radius: 10px
}
.mailcode {
  margin: 5px 0; 
  width: 163px
}
.login_button {
  margin: 10px 0; 
  text-align: right
}
.m5_0 {
  margin: 5px 0
}
.ml200 {
  margin-left: 200px
}
.br20 {
  border-radius: 20px
}
.width295 {
  width: 295px
}
.width180 {
  width: 180px
}
</style>
